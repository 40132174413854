import Glide from '@glidejs/glide'

const createGlider = () => {

  // Cards
  const glideCards = document.querySelectorAll('.js-glide-cards')
  let cards

  for (let i = 0; i < glideCards.length; i++) {
    cards = new Glide(glideCards[i], {
      type: 'carousel',
      perView: glideCards[i].getAttribute("data-perView") ? glideCards[i].getAttribute("data-perView") : 4,
      gap: 30,
      breakpoints: {
        1200: {
          perView: 3
        },
        900: {
          perView: 2
        },
        600: {
          perView: 1
        }
      }
    })
    cards.mount()
  }

  // Text
  const glideText = document.querySelectorAll('.js-glide-text')
  let text

  for (let i = 0; i < glideText.length; i++) {
    text = new Glide(glideText[i], {
      type: 'carousel'
    })
    text.mount()
  }

  // Gallery Single
  const glideGallerySingle = document.querySelectorAll('.js-glide-gallery-single')
  let gallerysingle

  for (let i = 0; i < glideGallerySingle.length; i++) {
    gallerysingle = new Glide(glideGallerySingle[i], {
      type: 'carousel',
      gap: 0
    })
    gallerysingle.mount()
  }

  // Gallery Multi
  const glideGalleryMulti = document.querySelectorAll('.js-glide-gallery-multi')
  let gallerymulti

  for (let i = 0; i < glideGalleryMulti.length; i++) {
    gallerymulti = new Glide(glideGalleryMulti[i], {
      type: 'carousel',
      perView: 4,
      gap: 30,
      breakpoints: {
        1200: {
          perView: 3
        },
        900: {
          perView: 2
        },
        600: {
          perView: 1
        }
      }
    })
    gallerymulti.mount()
  }
}

export default createGlider
