import ScrollTrigger from '@terwanerik/scrolltrigger'
import jump from 'jump.js'
import createMap from './map'
let navHeight = 80
if (window.innerWidth <= 640) {
  navHeight = 48
}

const createScrollTrigger = () => {

  // Init Map if in viewport
  const trigger = new ScrollTrigger({
    trigger: {
      toggle: {
        class: {
          in: 'c-section--show',
          out: 'c-section--hide'
        }
      }
    }
  })

  trigger.add('[data-map]', {
    once: true,
    offset: {
      element: {
        y: -navHeight
      }
    },
    toggle: {
      callback: {
        in: (trigger) => {
          createMap(trigger.element.getAttribute('data-lat'), trigger.element.getAttribute('data-lng'), trigger.element.getAttribute('data-zoom'))
        }
      }
    }
  })

  // Active nav if section is on top
  const addActiveClass = function(elements, id) {
    for (let i = 0; i < elements.length; ++i) {
      if (elements[i].getAttribute("href") === '#'+id) {
        elements[i].classList.add('is-active')
      }
    }
  }
  const removeActiveClass = function (elements) {
    for (let i = 0; i < elements.length; ++i) {
      elements[i].classList.remove('is-active')
    }
  }
  const sections = document.getElementsByClassName('c-section')
  const lastSection = sections[sections.length - 1]
  const anchors = document.querySelectorAll('.anchor')
  const anchorsSidebar = document.querySelectorAll('.anchor-sidebar')
  document.addEventListener('scroll', function () {
    let curPos = window.scrollY + (navHeight+5)

    if (window.scrollY <= navHeight) {
      removeActiveClass(anchors)
      removeActiveClass(anchorsSidebar)
    } else {
      for (let i = 0; i < sections.length; ++i) {
        let topPos = sections[i].offsetTop
        let bottomPos = topPos + sections[i].offsetHeight
        if (curPos >= topPos && curPos <= bottomPos && sections[i].getAttribute("data-nav")) {
          removeActiveClass(anchors)
          removeActiveClass(anchorsSidebar)
          addActiveClass(anchors, sections[i].id)
        }
      }
    }
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      if (lastSection.getAttribute("data-nav")) {
        removeActiveClass(anchors)
        removeActiveClass(anchorsSidebar)
        addActiveClass(anchors, lastSection.id)
      }
    }
  })
}

const createScrollButtons = () => {
  let $scrollerTop = document.getElementById('scrolltotop')
  if ($scrollerTop) {
    document.addEventListener('scroll', function () {
      if (document.documentElement.scrollTop > 500) {
        $scrollerTop.classList.add('c-scrolltotop--show')
      } else {
        $scrollerTop.classList.remove('c-scrolltotop--show')
      }
    })
  }

  const jumper = document.querySelectorAll('[data-ctrl=\'jumper\']')
  for (let i = 0; i < jumper.length; i++) {
    jumper[i].addEventListener('click', function (e) {
      e.preventDefault()
      const targetID = this.getAttribute('href')
      const targetAnchor = document.querySelector(targetID)
      jump(targetAnchor, {
        duration: 1000,
        offset: -navHeight,
        a11y: false
      })
    })
  }
}

export {
  createScrollTrigger,
  createScrollButtons
}
