const createMap = (lat, lng, zoom) => {
  let map = L.map('leaflet', {
    center: [lat, lng],
    zoom: zoom,
    scrollWheelZoom: false
  })

  L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}.png', {
    maxZoom: 18
  }).addTo(map)
  L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_only_labels/{z}/{x}/{y}.png', {
    maxZoom: 18,
    zIndex: 10,
    attribution: '© <a href="http://www.openstreetmap.org/copyright" target="_blank" rel="noreferrer">OpenStreetMap</a>, © <a href="https://carto.com/attribution" target="_blank" rel="noreferrer">CARTO</a>'
  }).addTo(map)
  let marker = L.marker([lat, lng]).addTo(map)
}

export default createMap
