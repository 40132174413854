import "../css/app.css";
import Alpine from "alpinejs"
import "lazysizes/plugins/bgset/ls.bgset"
// import "lazysizes/plugins/respimg/ls.respimg"
// import "lazysizes"
import createGlider from "./components/glide"
import createBaguette from "./components/baguette"
import { createScrollButtons, createScrollTrigger } from "./components/scroller"

// BUILD COMPONENTS
createBaguette()
createScrollTrigger()
createScrollButtons()
window.addEventListener("load", function () {
  createGlider()
})

if (document.getElementById("nav")) {
  document.addEventListener("scroll", function () {
    if (window.scrollY > 100) {
      document.getElementById("nav").classList.add("shadow")
    } else {
      document.getElementById("nav").classList.remove("shadow")
    }
  })
}

Alpine.data("nav", () => ({
  show: false,
  open() {
    this.show = true
  },
  close() {
    this.show = false
  },
  isOpen() {
    return this.show === true
  }
}))

Alpine.data("accordion", () => ({
  active: "",
  toggle(id) {
    this.active = this.active === id ? "" : id
  }
}))

Alpine.data("video", () => ({
  videoUrl: null,
  playVideo() {
    let src = ""
    const video = this.parseVideo(this.videoUrl)
    const iframe = document.createElement("iframe")

    if (video.type === "youtube") {
      src = `//www.youtube-nocookie.com/embed/${video.id}?rel=0&amp;autoplay=1`
    } else if (video.type === "vimeo") {
      src = `//player.vimeo.com/video/${video.id}?autoplay=1`
    }

    iframe.setAttribute("src", src)
    this.$refs.box.innerHTML = ""
    this.$refs.box.appendChild(iframe)
  },
  parseVideo(url) {
    let type
    url.match(
      /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com|be\-nocookie\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/
    )
    if (RegExp.$3.indexOf("youtu") > -1) {
      type = "youtube"
    } else if (RegExp.$3.indexOf("vimeo") > -1) {
      type = "vimeo"
    }

    return {
      type: type,
      id: RegExp.$6
    }
  }
}))

Alpine.start()

// window.delivery = function () {
//   return {
//     button: 'Jetzt bestellen',
//     sent: false,
//     validate: false,
//     url: '',
//     form: {
//       fullname: '',
//       street: '',
//       city: '',
//       email: '',
//       phone: '',
//       date: '',
//       checkbox: ''
//     },
//     products: [],
//     getProductCountById(id) {
//       console.log(id)
//       console.log(this.products)
//       const foundIndex = this.products.findIndex(x => x.id === id.toString())
//       console.log(foundIndex)
//       return this.products[foundIndex].count
//     },
//     countProductsInCart() {
//       return this.products.reduce(function (total, product) {
//         return total + product.count
//       }, 0)
//     },
//     addToCart(id) {
//       const foundIndex = this.products.findIndex(x => x.id === id.toString())
//       this.products[foundIndex].count++
//     },
//     removeFromCart(id) {
//       const foundIndex = this.products.findIndex(x => x.id === id.toString())
//       if (this.products[foundIndex].count >= 1) {
//         this.products[foundIndex].count--
//       }
//     },
//     getOrderProducts() {
//       return this.products.filter(function (product) {
//         return product.count > 0
//       })
//     },
//     getTotalOrderPrice() {
//       return this.products.reduce(function (total, product) {
//         return total + product.count * product.price
//       }, 0)
//     },
//     resetAll() {
//       this.validate = false
//       this.button = 'Jetzt bestellen'
//       this.form.fullname = ''
//       this.form.street = ''
//       this.form.city = ''
//       this.form.email = ''
//       this.form.phone = ''
//       this.form.date = ''
//       this.form.checkbox = ''
//       this.products = this.products.map(function (item) {
//         item.count = 0
//         return item
//       })
//     },
//     validateOrderForm() {
//       this.validate = true
//       const formdata = this.form
//       const validatedArray = Object.keys(formdata).filter(function (item) {
//         return formdata[item] === ''
//       })
//
//       if (!validatedArray.length) {
//         this.button = 'Einen Moment bitte...'
//         this.sendOrderMail()
//       }
//     },
//     sendOrderMail() {
//       let form = document.getElementById('deliveryForm')
//       let formData = new FormData(form)
//       const orderedProductsArray = this.getOrderProducts().map(function (item) {
//         return item.count + 'x ' + item.name + ' ' + (item.price * item.count).toFixed(2).replace('.', ',') + ' €'
//       })
//       const orderedProductsText = orderedProductsArray.join('\r\n')
//       formData.append('message[body]', orderedProductsText)
//
//       fetch(this.url + '/', {
//         method: 'POST',
//         body: formData,
//         headers: {
//           'Accept': 'application/json'
//         }
//       })
//           .then(response => response.json())
//           .then(data => {
//             console.log(data)
//             if (data.success) {
//               window.scrollTo({ top: 0 })
//               this.sent = true
//               this.resetAll()
//             }
//           })
//           .catch(err => {
//             console.log(err)
//           })
//     }
//   }
// }
